.main-body{
    
    padding-top: 3vh;
    min-height: 70vh;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 10vh;
    
    
}

.cont{
    
    padding-top: 3vh;
    
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 10vh;
    background-color: rgba(0, 0, 0, 0.6);
  
    
    
}

.cont1{
    
    padding-top: 3vh;
    
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 10vh;
  background-color: white;
  
    
    
}

.main-bg{
    background-image: url('https://admission.pragjyotishcollege.ac.in/images/bread-new-1.png');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
}

@media only screen and (max-width: 600px){
    .main-body{
        padding-left: 2vw;
        padding-right: 2vw;
    }
}

#b_ul li{
    width: 90px;
    text-align: center;
    border: solid thin #891AFF;
    border-bottom: solid thick #891AFF;
    margin-right: 10px;
    color: black;
}

.initialisation-container{
    background-color: #81D4FA;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

