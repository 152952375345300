.reg-form{
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.top-bar{
    height: 4px;
    width: 100%;
    background-color: #0029CB;
}

.form-contents{
    padding: 20px;
}

.container{
    background-image: ;
}